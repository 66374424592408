import React from "react";
import {
  HeroContainer,
  HeaderContainer,
  HeaderText,
  SubTextContainer,
  SubText,
  SubTextTwo,
  MainText,
} from "./styles";

const Main = () => {
  return (
    <HeroContainer>
      <HeaderContainer>
        <HeaderText>Allgemeine Geschäftsbedingungen (AGB)</HeaderText>
        <SubText>Schwimm- und Fitnesstraining durch Joinpuya</SubText>
      </HeaderContainer>
      <SubTextContainer>
        <SubText>Geltungsbereich:</SubText>
        <MainText>
          Für die Geschäftsbeziehung zwischen dem Schwimm- und Fitnesstrainer Pouya Pourebrahim (Joinpuya)  (im Folgenden "Trainer") und dem/der Kund*in (im Folgenden "Kund*in“) gelten ausschließlich die nachfolgenden Allgemeinen Geschäftsbedingungen (AGB). Abweichende Bedingungen des/der Kund*in werden nicht anerkannt, es sei denn, der Trainer stimmt ihrer Geltung ausdrücklich schriftlich zu.
        </MainText>

        <SubTextTwo>Vertragsgegenstand:</SubTextTwo>
        <MainText>
          Der Trainer bietet individuelle Schwimm- und Fitnesstrainings an. Diese sind in ihrer aktuellen Form auf der Website www.joinpuya.de zu finden. Der genaue Umfang und Inhalt des Trainings wird im Rahmen des jeweiligen Vertragsverhältnisses festgelegt. Der Vertrag kommt durch die Buchung des Kunden und die Bestätigung des Trainers zustande.
        </MainText>

        <SubTextTwo>Teilnahmevoraussetzungen:</SubTextTwo>
        <MainText>
          Der/Die Kund*in bestätigt mit seiner/ihrer Buchung, dass er/sie körperlich in der Lage ist, an den Trainingsstunden teilzunehmen. Bei bekannten gesundheitlichen Einschränkungen, Erkrankungen oder Verletzungen ist der Trainer vor Beginn der Trainingseinheit zu informieren. Der/Die Kund*in nimmt auf eigenes Risiko am Training teil. Sollten während des Trainings plötzliche Gesundheits,- oder Befindlichkeitsstörungen auftreten, so ist der Trainer umgehend darüber in Kenntnis zu setzen.
        </MainText>

        <SubTextTwo>Leistungsumfang und -durchführung:</SubTextTwo>
        <MainText>
          Der Trainer verpflichtet sich, die vereinbarten Trainingseinheiten sorgfältig und nach bestem Wissen und Gewissen durchzuführen.
          Eine Trainingseinheit dauert in der Regel 60 Minuten. Längere Trainingseinheiten erfolgen nach individueller Absprache.
          Die Schwimmtrainings finden im Kombibad Seestraße in Berlin (Seestr. 80, 13347 Berlin) und die Fitnesstrainings im Crunch Fit Wedding (Pankstr. 32-39, 13357 Berlin) statt. Andere Trainingsorte sind nach voriger Rücksprache ggf. möglich.
        </MainText>

        <SubTextTwo>Zahlungsbedingungen:</SubTextTwo>
        <MainText>
          Das Honorar für die Trainingseinheiten wird vor Beginn des Trainings vereinbart und ist nach Erbringung der Leistung sofort fällig, sofern nichts anderes vereinbart wurde. Die Zahlung erfolgt per Überweisung auf das Geschäftskonto von Joinpuya. Bei wiederkehrenden Leistungen (z. B. monatliche Abos) erfolgt die Abrechnung im Voraus.
        </MainText>

        <SubTextTwo>Stornierung und Absagen:</SubTextTwo>
        <MainText>
          Der Trainer behält sich jedoch vor, Trainingseinheiten zu verschieben oder abzusagen, wenn dies aus Gründen wie Krankheit, unvorhersehbaren Ereignissen oder höherer Gewalt erforderlich ist. In diesen Fällen wird ein Ersatztermin vereinbart.
        </MainText>
        <MainText>
          Der/Die Kund*in kann gebuchte Trainingseinheiten bis zu 24 Stunden vor Beginn kostenfrei stornieren. Bei späteren Absagen oder Nichterscheinen wird das volle Honorar fällig, es sei denn, der/die Kund*in kann einen wichtigen Grund für die Absage nachweisen (z. B. Krankheit).
        </MainText>
        <SubTextTwo>Gutscheine:</SubTextTwo>
         <MainText>
          Gutscheine sind grundsätzlich nicht umtauschbar und können nicht erstattet werden. Dies gilt sowohl für Geschenkgutscheine als auch für Rabattgutscheine. Eine Rückgabe oder Erstattung des Gutscheinwertes ist ausgeschlossen, auch im Falle einer Teilausnutzung oder bei Verlust des Gutscheins. Gutscheine können ausschließlich für den Erwerb von Waren oder Dienstleistungen auf unserer Website verwendet werden und sind an die dort angegebenen Bedingungen gebunden.
         </MainText>

        <SubTextTwo>Haftung:</SubTextTwo>
        <MainText>
          Der Trainer haftet für Schäden, die vorsätzlich oder grob fahrlässig verursacht wurden. Für einfache Fahrlässigkeit haftet der Trainer nur bei Verletzung wesentlicher Vertragspflichten und nur im Rahmen des vorhersehbaren, vertragstypischen Schadens.
        </MainText>
        <MainText> Der Trainer haftet nicht für Schäden, welche aufgrund der Selbstüberschätzung beim/bei der Kund*in zustande gekommen sind. </MainText>
        <MainText>Für Garderobe und persönliche Gegenstände wird keine Haftung übernommen. Dem/Der Kund*in wird ausdrücklich geraten, keine Wertgegenstände mitzubringen. Von Seiten des Trainers werden keinerlei Bewachung und Sorgfaltspflichten für dennoch eingebrachte Wertgegenstände übernommen.</MainText>
        <MainText>Der Trainer verfügt über eine angemessene Betriebshaftpflichtversicherung.</MainText>

        <SubTextTwo>Geheimhaltung:</SubTextTwo>
        <MainText>
          Der Trainer ist verpflichtet, über alle im Zusammenhang mit der Erfüllung der Trainingsmaßnahmen bekannt gewordenen Informationen des/der Kund*in Stillschweigen zu bewahren. Dies gilt auch nach der Beendigung des Vertragsverhältnisses.
        </MainText>

        <SubTextTwo>Datenschutz:</SubTextTwo>
        <MainText>
          Der Trainer verpflichtet sich, alle persönlichen Daten des/der Kund*in vertraulich zu behandeln und ausschließlich zur Vertragsabwicklung und zur Durchführung der Trainingseinheiten zu verwenden. Die Daten werden nicht an Dritte weitergegeben, es sei denn, dies ist zur Vertragserfüllung erforderlich oder gesetzlich vorgeschrieben.
          Weitere Informationen sind unter dem Punkt Datenschutzerklärung zu finden.
        </MainText>

        <SubTextTwo>Schlussbestimmungen:</SubTextTwo>
        <MainText>
          Der Trainer behält sich das Recht vor, diese AGB jederzeit zu ändern. Änderungen werden dem/der Kund*in in geeigneter Weise bekannt gegeben. Widerspricht der/die Kund*in den Änderungen nicht innerhalb von 14 Tagen nach Bekanntgabe, gelten die geänderten AGB als akzeptiert.
        </MainText>
        <MainText>Der Gerichtsstand richtet sich nach den gesetzlichen Bestimmungen.</MainText>
        <MainText>Es gilt das Recht der Bundesrepublik Deutschland.</MainText>

        <SubTextTwo>Salvatorische Klausel:</SubTextTwo>
        <MainText>
          Sollten einzelne Bestimmungen dieses Vertrages unwirksam oder undurchführbar sein oder nach Vertragsschluss unwirksam oder undurchführbar werden, bleibt davon die Wirksamkeit des Vertrages im Übrigen unberührt. An die Stelle der unwirksamen oder undurchführbaren Bestimmung soll diejenige wirksame und durchführbare Regelung treten, deren Wirkungen der wirtschaftlichen Zielsetzung am nächsten kommen, die die Vertragsparteien mit der unwirksamen bzw. undurchführbaren Bestimmung verfolgt haben
        </MainText>

        <SubTextTwo>Stand: 5. Januar 2025</SubTextTwo>

      </SubTextContainer>
    </HeroContainer>
  );
};

export default Main;
