import React from "react";
// Router //
import { HashRouter as Router, Route, Routes } from "react-router-dom";
// Top scroll //
import ScrollToTop from "./ScrollToTop";
// pages //
import {
  Home,
  PersonalTrainer,
  Nutrition,
  Trainning,
  Trip,
  DaysTransForm,
  SBeginner,
  SAdvance,
  SKids,
  About,
  Offers,
  BookNow,
  VideoPage,
  VideoPlay,
  Impressum,
  Datenshutz,
  Agb,
  Form,
} from "./Pages";
//Cookie //
import { CookieConsent } from "./Compoents";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <CookieConsent />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/personal-training" element={<PersonalTrainer />} />
        <Route path="/ernahrungsplan" element={<Nutrition />} />
        <Route path="/training-plan" element={<Trainning />} />
        <Route path="/neunzig-tage-transformation" element={<DaysTransForm />} />
        <Route
          path="/schwimmen"
          element={<SAdvance />}
        />
        <Route path="/uber-mich" element={<About />} />
        <Route path="/angebote" element={<Offers />} />
        <Route path="/jetzt-buchen" element={<BookNow />} />
        <Route path="/videos-home" element={<VideoPage />} />
        <Route path="/video-play" element={<VideoPlay />} />
        {/* Unused */}
        <Route path="/fitness-reise" element={<Trip />} />
        <Route path="/swimming-training-for-children" element={<SKids />} />
        <Route
          path="/swimming-training-for-beginners"
          element={<SBeginner />}
        />
        {/* Owner */}
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/datenshutz" element={<Datenshutz />} />
        <Route path="/agb" element={<Agb />} />
        <Route path="/form" element={<Form />} />
      </Routes>
    </Router>
  );
}

export default App;
