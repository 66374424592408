import styled from "styled-components";

interface PopupContainerProps {
  show: boolean;
}

export const PopupContainer = styled.div<PopupContainerProps>`
  display: ${({ show }) => (show ? "flex" : "none")};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
`;

export const PopupContent = styled.div`
  background: white;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 960px) {
    width: 250px;
  }
`;

export const PopupImage = styled.img`
  width: 120px;
  height: 200px;
  object-fit: cover;
`;

export const PopupText = styled.span`
  color: #001524;
  font-size: 18px;
  font-weight: bold;
  opacity: 0.8;
  margin-bottom: 10px;
  @media screen and (max-width: 960px) {
    font-size: 14px;
    font-weight: 900;
    margin-top: 10px;
  }
`;

export const PopupTextCountDown = styled.span`
  color: #8f7b45;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  @media screen and (max-width: 960px) {
    font-size: 14px;
    font-weight: 900;
    margin-top: 10px;
  }
`;

export const BtnContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

export const BtnContainer = styled.div`
  display: flex;
  height: 30px;
  width: 100px;
  border-radius: 100px;
  border: 1px solid #000;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #000;
  }
`;

export const BtnText = styled.span`
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  opacity: 0.7;
  color: #000;
  &:hover {
    color: #fff;
  }
`;
