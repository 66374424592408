import React from "react";
import {
  HeroContainer,
  HeaderContainer,
  SubText,
  ImgContainer,
  Img,
} from "./styles";
import ProfileSwim from "../../../assets/imgs/aboutImgFour.png";

const Feat = () => {
  return (
    <HeroContainer>
      <ImgContainer>
        <Img src={ProfileSwim} />
      </ImgContainer>
      <HeaderContainer>
        <SubText>
          Ich bringe jahrelange Erfahrung als Schwimmtrainer und eine Leidenschaft für das Wasser
          mit. Ich gestalte jeden Schwimmunterricht individuell und achte
          darauf, dass du die persönliche Aufmerksamkeit erhältst, die du
          verdienst. Ich komme mit dir ins Wasser und werde dir alles vorzeigen.
          Ich unterrichte Kinder ab 4 Jahren. Der Schwimmunterricht für Kinder
          ist der perfekte Weg, um Sicherheit im Wasser zu gewinnen und
          gleichzeitig jede Menge Spaß zu haben! In meine inidividuell
          gestalteten Trainings lernen die Kinder auf spielerische Weise das
          Schwimmen. Ich vermittele Schritt für Schritt die wichtigsten
          Techniken, damit dein Kind selbstbewusst und sicher schwimmen kann.
          Viele Menschen lernen in Deutschland während der Schulzeit das
          Brustschwimmen. In meinen Kursen kannst du zusätzlich professionell
          das Kraulschwimmen lernen. Außerdem kann ich dich auch unterstützen, wenn
          du dich auf bestimmte Ziele, wie etwa einen Triathlon oder
          Freiwasserschwimm-Wettkämpfe, vorbereitest.
        </SubText>
      </HeaderContainer>
    </HeroContainer>
  );
};

export default Feat;
